<template>
  <div>
    <ErrorPage v-if="showError" />
    <div v-else>
      <loader v-if="isLoading" size="xxs" :loader-image="false" />
      <div v-else class="flex flex-col gap-4 w-full">
        <c-card class="w-full flex flex-col p-5">
          <CardFooter
            class="-mt-5"
            reloadcard
            search-placeholder="Search by employee name"
            @reload="
              $route.query.slug === 'admin'
                ? getAdminAnnualPlanDetails()
                : getManagerAnnualPlanDetails()
            "
            @actionModal="onFilter"
            @sortType="onGetQuery({ sort: $event })"
            @searchResult="onGetQuery({ search: $event })"
          />
        </c-card>

        <c-table
          :headers="headers"
          :items="leavePlanDetails"
          aria-label="annual leave plan table"
          :loading="isFetching"
          :has-number="true"
          :page-sync="true"
          :pagination-list="pagination"
          @page="onGetQuery({ page: $event })"
          @itemsPerPage="onGetQuery({ perPage: $event })"
        >
          <template v-slot:item="{ item }">
            <div v-if="item.fname" class="flex justify-start items-start gap-2">
              <div>
                <span
                  v-if="item.data.photo"
                  class="flex justify-center items-center w-9 h-9"
                >
                  <img
                    :src="item.data.photo"
                    class="w-9 h-9 rounded"
                    alt="profile photo"
                  />
                </span>
                <div
                  v-else
                  class="flex justify-center items-center w-9 h-9 rounded border"
                >
                  <span
                    class="text-blueCrayola text-center font-semibold text-lg p-2"
                  >
                    {{ $getInitials(`${item.data.fname} ${item.data.lname}`) }}
                  </span>
                </div>
              </div>
              <div class="flex flex-col justify-start gap-1">
                <span class="font-semibold text-darkPurple text-base">
                  {{ item.data.fname }} {{ item.data.lname }}
                </span>
                <span class="font-normal text-romanSilver text-xs uppercase">
                  {{
                    item.data.userDesignation && item.data.userDesignation.name
                  }}
                </span>
              </div>
            </div>
            <span
              v-if="item.leaveType"
              class="text-darkPurple text-sm font-normal"
            >
              -
            </span>
            <span
              v-if="item.maximumApplicableDays"
              class="text-darkPurple text-sm font-normal"
            >
              {{ item.data.maximumApplicableDays }}
            </span>
            <span
              v-if="item.totalPlannedLeaveDays"
              class="text-darkPurple text-sm font-normal"
            >
              {{ item.data.totalPlannedLeaveDays }}
            </span>
            <span
              v-if="item.totalUnplannedLeaveDays"
              class="text-darkPurple text-sm font-normal"
            >
              {{ item.data.totalUnplannedLeaveDays }}
            </span>
            <span
              v-if="item.updatedAt"
              class="text-darkPurple text-sm font-normal"
            >
              {{ $DATEFORMAT(new Date(item.data.updatedAt), "MMMM dd, yyyy") }}
              <br />
              <span class="text-xs font-semibold text-blueCrayola">{{
                $DATEFORMAT(new Date(item.data.updatedAt), "hh:mm a")
              }}</span>
            </span>
            <c-menu v-if="item.id" left @click.stop="">
              <template v-slot:title>
                <c-icon icon-name="more_icon" size="xs" />
              </template>
              <div
                class="flex flex-col w-48 h-18 p-2 justify-start items-start"
              >
                <div
                  class="w-full"
                  @click="
                    checkPrivilege('view Employee', {
                      id: item.data.id,
                      leaveYear: leaveYear,
                    })
                  "
                >
                  <div class="hover:bg-ghostWhite w-full rounded-md">
                    <div
                      class="flex justify-start items-center p-2 gap-2 cursor-pointer"
                    >
                      <Icon
                        icon-name="icon-eye"
                        class-name="text-darkPurple"
                        size="xs"
                      />
                      <span
                        class="text-darkPurple text-sm leading-5 font-normal"
                        >View</span
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="w-full"
                  @click="checkPrivilege('approve tentatively', item.data.id)"
                >
                  <div class="hover:bg-ghostWhite w-full rounded-md">
                    <div
                      class="flex justify-start items-center p-2 gap-2 cursor-pointer"
                    >
                      <Icon
                        icon-name="icon-upgrade-1"
                        class-name=""
                        size="xs"
                      />
                      <span
                        class="text-darkPurple text-sm leading-5 font-normal"
                        >Approve Tentatively</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </c-menu>
          </template>
        </c-table>

        <FilterTable
          v-if="isOpenFilter"
          :filter-data="filterOptions"
          @close="isOpenFilter = false"
          @submit="isOpenFilter = false"
        />

        <c-modal v-if="isOpenApproval">
          <c-card class="p-2 px-3 flex flex-col gap-3">
            <div class="border-b border-romanSilver">
              <p v-if="!isApproving" class="text-jet font-bold text-base">
                Approve Tentatively?
              </p>
              <p v-else class="text-jet font-bold text-base">
                Approving, Please wait...
              </p>
            </div>
            <div class="flex justify-end items-center gap-2">
              <c-button
                :disabled="isApproving"
                label="Yes"
                class="button-class-pimary btn-bg"
                @onClick="onSubmitApproval"
              />
              <c-button
                :disabled="isApproving"
                label="No"
                class="button-class-secondary btn-border"
                @onClick="isOpenApproval = false"
              />
            </div>
          </c-card>
        </c-modal>
      </div>
    </div>
  </div>
</template>

<script>
import CTable from "@scelloo/cloudenly-ui/src/components/table";
import CCard from "@scelloo/cloudenly-ui/src/components/card";
import CButton from "@scelloo/cloudenly-ui/src/components/button";
import CMenu from "@scelloo/cloudenly-ui/src/components/Menu";
import CardFooter from "@/components/CardFooter";
import FilterTable from "@/components/FilterTable";
import CIcon from "@/components/Icon";
import CModal from "@/components/Modal";
import ErrorPage from "@/modules/Admin/error403";

export default {
  components: {
    CCard,
    CTable,
    CButton,
    CMenu,
    CardFooter,
    FilterTable,
    CIcon,
    CModal,
    ErrorPage,
  },
  data() {
    return {
      isOpenFilter: false,
      isOpenApproval: false,
      showError: false,
      isLoading: true,
      isFetching: true,
      isApproving: false,
      filterOptions: [],
      setQueryTimeout: null,
      leaveYear: "",
      planId: null,
      queryParams: {},
      leavePlanDetails: [],
      pagination: {},
      summary: {
        totalmaximumApplicableDays: 0,
        daysUsed: 0,
        leaveBalance: 0,
      },
      balances: [],
      headers: [
        { title: "Employee Name", value: "fname" },
        {
          title: "Total Leave Entitlement (Days)",
          value: "maximumApplicableDays",
        },
        { title: "Total Planned Leave (Days)", value: "totalPlannedLeaveDays" },
        {
          title: "Total Unplanned Leave (Days)",
          value: "totalUnplannedLeaveDays",
        },
        { title: "Last Modified", value: "updatedAt" },
        { title: "", value: "id", image: true },
      ],
    };
  },
  methods: {
    async onGetQuery(params) {
      try {
        const queryString = Object.keys(params)
          .map((key) => `${key}=${params[key]}`)
          .join("&");
        if (this.$route.query.slug === "admin") {
          await this.getAdminAnnualPlanDetails(queryString);
        } else {
          await this.getManagerAnnualPlanDetails(queryString);
        }
      } catch (error) {
        const message = "You do not have permission to perform this task";
        this.$toasted.error(message, { duration: 3000 });
      }
    },

    async checkPrivilege(data, arg) {
      switch (data) {
        case "approve tentatively":
          try {
            await this.$handlePrivilege("leavePlan", "approveTentatively");
            this.isOpenApproval = true;
            this.planId = arg;
          } catch (error) {
            this.$toasted.error(
              "You do not have permission to perform this task",
              {
                duration: 5000,
              }
            );
          }
          break;

        case "view Employee":
          if (this.$route.query.slug === "admin") {
            try {
              await this.$handlePrivilege("leavePlan", "viewEmployee");
              this.$router.push({
                name: "ViewEmployeeAnnualLeavePlan",
                params: { year: arg.leaveYear, id: arg.id },
                query: { mode: this.$route.query.slug },
              });
            } catch (error) {
              this.$toasted.error(
                "You do not have permission to perform this task",
                {
                  duration: 5000,
                }
              );
            }
          } else {
            this.$router.push({
              name: "EssViewEmployeeAnnualLeavePlan",
              params: { year: arg.leaveYear, id: arg.id },
              query: { mode: this.$route.query.slug },
            });
          }
          break;
        default:
          break;
      }
    },

    onFilter() {
      this.filterOptions = [
        { header: "Location(s)", optionsBox: this.locationOptions },
        { header: "Function(s)", optionsBox: this.functionOptions },
        { header: "Level(s)", optionsBox: this.levelOptions },
        { header: "Designation(s)", optionsBox: this.designationOptions },
      ];
      this.isOpenFilter = true;
    },
    onSubmitApproval() {
      this.isApproving = true;
      const payload = { leavePlanId: this.planId };

      this.$_postTentativeApproval(payload)
        .then(({ data }) => {
          this.$toasted.success(data.message, { duration: 3000 });
          this.isApproving = false;
          this.isOpenApproval = false;
        })
        .catch((error) => {
          this.$toasted.error(error.message, { duration: 3000 });
          this.isApproving = false;
        });
    },
    async getAdminAnnualPlanDetails(params) {
      this.isFetching = true;

      const payload = { leaveYear: this.leaveYear, params };
      await this.$_getAdminAnnualPlanDetails(payload).then(({ data }) => {
        this.leavePlanDetails = data.data;
        this.pagination = data.meta;
        this.isFetching = false;
      });
    },

    async getManagerAnnualPlanDetails(params) {
      this.isFetching = true;

      const payload = { leaveYear: this.leaveYear, params };
      await this.$_getManagerLeaveDetails(payload.leaveYear, params).then(
        ({ data }) => {
          this.leavePlanDetails = data.data;
          this.pagination = data.meta;
          this.isFetching = false;
        }
      );
    },

    async bootstrapModule() {
      this.isLoading = true;
      this.leaveYear = this.$route.params.leaveYear;

      if (this.$route.query.slug === "admin") {
        try {
          await this.$handlePrivilege(
            "leavePlan",
            "accessDetailedViewRegister"
          );
          this.showError = false;
          await this.getAdminAnnualPlanDetails();
          this.isLoading = false;
        } catch (error) {
          this.showError = true;
        }
      } else {
        this.getManagerAnnualPlanDetails("");
        this.isLoading = false;
      }
    },
  },
  created() {
    this.bootstrapModule();
  },
};
</script>

<style>
.userContainer {
  padding: 8px;
  border-bottom: 1px dashed #878e99;
}

input[type="radio"] {
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  outline: none;
  box-shadow: 0px 0px 0px 2px #eeeeee inset;
}

input[type="radio"]:checked {
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  outline: none;
  background-color: #f15a29 !important;
  box-shadow: 0px 0px 0px 2px #eeeeee inset;
}

.button-class-pimary {
  font-weight: 600 !important;
  font-size: 16px !important;
  padding: 10px 50px !important;
}
.button-class-secondary {
  color: #321c3b !important;
  background: white !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  padding: 10px 50px !important;
}

.btn-bg {
  background-color: var(--dynamic-Background) !important;
}

.btn-border {
  color: var(--dynamic-Background) !important;
  border: 1px solid var(--dynamic-Background) !important;
}
</style>
